import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../app/store";

export interface General {
  cms: any | null;
  isAuthModalVisible: boolean;
  isGuestModalVisible: boolean;
  ModalType: string;
}

const initialState: General = {
  cms: null,
  isAuthModalVisible: false,
  isGuestModalVisible: false,
  ModalType: "",
};

export const generalSlice = createSlice({
  name: "general",
  initialState,
  reducers: {
    resetCms: (state, action: PayloadAction<Pick<General, "cms">>) => {
      state.cms = action.payload.cms;
    },
    toggleAuthModal: (
      state,
      action: PayloadAction<Pick<General, "isAuthModalVisible" | "ModalType">>
    ) => {
      state.isAuthModalVisible = action.payload.isAuthModalVisible;
      state.ModalType = action.payload.ModalType;
    },
    toggleGuestModal: (state, action: PayloadAction<Pick<General, "isGuestModalVisible" | "ModalType">>) => {
      state.isGuestModalVisible = action.payload.isGuestModalVisible;
      state.ModalType=action.payload.ModalType
      console.log(action.payload.isGuestModalVisible)
    },
  },
});
export const { resetCms, toggleAuthModal,toggleGuestModal } = generalSlice.actions;
export const getCms = (state: RootState) => state.general.cms;
export const isAuthModalVisible = (state: RootState) =>
  state.general.isAuthModalVisible;
export const isGuestModalVisible = (state: RootState) =>
  state.general.isGuestModalVisible
export const ModalType = (state: RootState) => state.general.ModalType;

export default generalSlice.reducer;

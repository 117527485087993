import { useEffect } from "react";
import "./App.scss";
import Routing from "./Routes";


import { firebaseCloudMessaging } from "./utils/firebase";
import { Socket_URL, socket } from "./utils/sockets";
import { useAppSelector } from "./hooks/store";
import { getToken } from "./reducers/authSlice";
import { removeFromStorage, setToStorage } from "./constants/storage";
import { toast } from "sonner";

function App() {
  const socketToken = useAppSelector(getToken);
  console.log(socketToken, "tototoo");

  function onConnect() {
    console.log("connected");
  }

  function onDisconnect() {
    console.log("disconnected");
  }

  function onError(error: any) {
    console.error("Socket connection error:", error);
  }

  var options = {
    enableHighAccuracy: true,
    timeout: 5000,
    maximumAge: 0,
  };

  const success = (pos: any) => {
    var crd = pos.coords;
    setToStorage("lat", crd.latitude);
    setToStorage("long", crd.longitude);
    console.log("Your current position is:");
    console.log(`Latitude : ${crd.latitude}`);
    console.log(`Longitude: ${crd.longitude}`);
    console.log(`More or less ${crd.accuracy} meters.`);
  };

  const errors = (err: any) => {
    console.warn(`ERROR(${err.code}): ${err.message}`);
  };

  useEffect(() => {
    const fetchGeolocation = async () => {
      if (navigator.geolocation) {
        try {
          const result = await navigator.permissions.query({
            name: "geolocation",
          });
          console.log(result);

          if (result.state === "granted" || result.state === "prompt") {
            navigator.geolocation.getCurrentPosition(success, errors, options);
          } else if (result.state === "denied") {
            console.log("Geolocation access denied.");
          }
        } catch (error) {
          console.error("Error checking geolocation permissions:", error);
        }
      } else {
        console.log("Geolocation is not supported by this browser.");
      }
    };

    fetchGeolocation();
    return () => {
      removeFromStorage("lat");
      removeFromStorage("long");
    };
  }, []);

  useEffect(() => {
    async function setToken() {
      try {
        const fcmToken = await firebaseCloudMessaging.init();
        if (fcmToken) {
          console.log("FCM Token:", fcmToken);
        }
      } catch (error) {
        console.error("Error initializing FCM:", error);
      }
    }

    setToken();
    
    const handleMessage = (event: MessageEvent) => {
      console.log(event,"event")
      try {
        event?.source?.postMessage("Hi client");
        toast.info(event?.data?.notification.title ||"notification received");
      } catch (error) {
        console.error("Error handling message:", error);
      }
    };

    if ("serviceWorker" in navigator) {
      navigator.serviceWorker.addEventListener("message", handleMessage);
    }

    if ("permissions" in navigator) {
      navigator.permissions
        .query({ name: "notifications" })
        .then((notificationPerm) => {
          notificationPerm.onchange = () => {
            if (notificationPerm.state === "granted") {
              setToken();
            }
          };
        });
    }

    return () => {
      if ("serviceWorker" in navigator) {
        navigator.serviceWorker.removeEventListener("message", handleMessage);
      }
    };
  }, []);

  useEffect(() => {
    // Check if the socket token is available and has a length
    if (socketToken?.length) {
      // Construct the modified URL with the socket token as a query parameter
      const modifiedURL = `${Socket_URL}?token=${socketToken || ""}`;
      // Update the socket's URI with the modified URL
      socket.io.uri = modifiedURL;
      // If the socket is not already connected, connect it
      if (!socket?.connected) {
        socket.connect();
      }
      // Set up event listeners for the socket connection
      socket.on("connect", onConnect);
      socket.on("disconnect", onDisconnect);
      socket.on("error", onError);

      // If the socket token is not available, disconnect the socket
      return () => {
        socket.disconnect();
        socket.off("connect", onConnect);
        socket.off("disconnect", onDisconnect);
        socket.off("error", onError);
      };
    } else {
      socket.disconnect();
    }
  }, [socketToken]); // Dependency array to re-run the effect when socketToken changes

  return <Routing />;
}

export default App;

/**
 * Sets a value in local storage.
 * @param {string} key - The key under which the data will be stored.
 * @param {any} data - The data to store.
 */

export const setToStorage = (key: string, data: any) => {
  localStorage.setItem(key, data);
};

/**
 * Retrieves a value from local storage.
 * @param {string} key - The key of the data to retrieve.
 * @returns {string | null} - The retrieved data, or null if the key does not exist.
 */

export const getFromStorage = (key: string) => {
  return localStorage.getItem(key);
};
/**
 * Removes a value from local storage.
 * @param {string} key - The key of the data to remove.
 */
export const removeFromStorage = (key: string) => {
  localStorage.removeItem(key);
};

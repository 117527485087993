import crypto from "crypto-js";

/**
 * Function to generate a symmetric encryption key (SEK) and its hash.
 * @param {string | null} token - The token used for authorization.
 * @returns {{hash: string, sek: string} | null} - Returns an object with hash and sek if successful, otherwise null.
 */

export function generateSEKAndHash(token: string | null) {
  // Initialization vector for AES encryption
  const iv = "3t4p2jkrtyhsoirp".slice(0, 16);
  try {
    // Generate the current timestamp and device ID
    const timestamp = new Date().toISOString();
    console.log(timestamp, "timestamp");

    const deviceId = navigator.userAgent;

    const key = crypto.lib.WordArray.random(32);
    let dataPayload = {};
    // Create the data payload based on the presence of the token
    if (token === "" || token === null || token === undefined) {
      dataPayload = {
        appKey: timestamp,
      };
    } else {
      dataPayload = {
        authorization: `Bearer ${token}`,
        appKey: new Date().toISOString(),
        // deviceid: timestamp,
      };
    }
    let appkey = JSON.stringify(dataPayload);
    // Encrypt the data payload using AES encryption
    const encrypted = crypto.AES.encrypt(appkey, key, {
      iv: crypto.enc.Utf8.parse(iv),
      mode: crypto.mode.CBC,
    });
    const encryptedHex = encrypted.ciphertext.toString();
    const keyHash = key.toString();
    // Return the hash and encrypted data
    return {
      hash: keyHash,
      sek: encryptedHex,
    };
  } catch (error) {
    console.error("", error);
    return null;
  }
}

export function generateEncryptedKeyBody(body: any) {
  const iv = "3t4p2jkrtyhsoirp".slice(0, 16);
  try {
    const key = crypto.lib.WordArray.random(32);
    let dataPayload = JSON.stringify(body);

    const encrypted = crypto.AES.encrypt(dataPayload, key, {
      iv: crypto.enc.Utf8.parse(iv),
      mode: crypto.mode.CBC,
    });
    const encryptedHex = encrypted.ciphertext.toString();
    const keyHash = key.toString();

    return {
      hash: keyHash,
      sek: encryptedHex,
    };
  } catch (error) {
    console.error("", error);
    return null;
  }
}

import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { Toaster } from 'sonner'
import { ThemeProvider } from "@mui/material";
import theme from "./config";
import { Provider } from "react-redux";
import { store } from "./app/store";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    
     
    <Toaster richColors duration={2000} position="top-center" closeButton />
      <Provider store={store}>

      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
      </Provider>
    
  </React.StrictMode>
);

reportWebVitals();

import {
  createApi,
  fetchBaseQuery,
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/query/react";
import type { RootState } from "../app/store";
import { API_URL } from "../constants/url";
import { resetAuth } from "../reducers/authSlice";
import { generateEncryptedKeyBody, generateSEKAndHash } from "./crypto";
import { errorToast } from "../constants/toasts";
import { removeFromStorage } from "../constants/storage";
import { STORAGE_KEYS } from "../constants/storageKeys";


type CommonBody = {
  hash: string;
  sek: string;
};
// Configure the base query with default settings and headers
const baseQuery = fetchBaseQuery({
  baseUrl: API_URL,

  prepareHeaders: (headers, { getState }) => {
    // Get the current authentication tokens from the state
    const { token } = (getState() as RootState).auth;
    const { tempToken } = (getState() as RootState).auth;
    const appkey = {
      appKey: new Date().toISOString(),
    };

    console.log(tempToken, "temptoken ggg");
    // Generate hashed keys using the token or temporary token
    const hashedAppKey = generateSEKAndHash(token || tempToken);

    // Set the necessary headers for the request
    headers.set("hash", `${hashedAppKey?.hash}`);
    headers.set("sek", `${hashedAppKey?.sek}`);
    headers.set("deviceType", "web");
    return headers;
  },
});

// Enhance the base query to handle encrypted request bodies and authentication errors
const baseQueryWithAuth: BaseQueryFn<
  string | FetchArgs | any,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  if (args.body) {
    const encryptedData = generateEncryptedKeyBody(args.body) as CommonBody;

    if (encryptedData) {
      args.body = encryptedData;
    } else {
      return {
        error: {
          status: 400,
          data: { detail: "Failed to encrypt request body" },
        },
      };
    }
  }
  // Execute the base query
  const result = await baseQuery(args, api, extraOptions);
  if (result.error?.status === 400) {
    const errors = Object.values(result?.error?.data || {});
    console.log({ errors });
    if (errors?.length > 1 && errors[1] === 400) {
      return result;
    }

    if (errors?.length) {
      const error = errors[0] as any;
      if (error?.length) {
        console.log(error[0]);
      }
    }
  }
  if (result.error?.status === 401) {
    removeFromStorage(STORAGE_KEYS.token);
    removeFromStorage(STORAGE_KEYS.userData);
    api.dispatch(resetAuth());

    errorToast("Unauthorized Access");
    window.location.replace("/");
  }
  if (
    result.error &&
    result.error.status === 401 &&
    (api.getState() as RootState).auth.user
  ) {
    removeFromStorage(STORAGE_KEYS.token);
    removeFromStorage(STORAGE_KEYS.userData);
    api.dispatch(resetAuth());

    errorToast("Unauthorized Access");
    window.location.replace("/");
  }

  return result;
};
// Create an empty API with base query and common configuration
const emptySplitApi = createApi({
  baseQuery: baseQueryWithAuth,
  tagTypes: ["UNAUTHORIZED", "UNKNOWN_ERROR", "Property"],
  refetchOnReconnect: true,
  keepUnusedDataFor: 3600,
  endpoints: () => ({}),
});

export default emptySplitApi;
